import { Box, Flex, Heading, Image, List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { MdArrowForward } from 'react-icons/md'

const LatestItems2 = () => {
  return (
    <>
      <Flex align="center" justify="center">
        <Heading mt="20px" size={'md'} mb="20px">Centre of Excellence</Heading>
      </Flex>
      <Flex  align="center" justify="center">
      <List spacing={3}>
      <ListItem _hover={{ boxShadow: 'xl' }}>
        <a href='/servicepages/fertilitycare'>
        <Image width="300px" height="150px" boxShadow={'lg'} borderRadius={'2xl'} src={require('../img/centrelogos/fertility.jpg')} alt='Fertility Centre' />
        </a>
        </ListItem>
        <ListItem _hover={{ boxShadow: 'xl' }}>
        <a href='/heart-centre'>
        <Image width="300px" height="150px" boxShadow={'lg'} borderRadius={'2xl'} src={require('../img/centrelogos/heartcentre.jpg')} alt='Heart Centre' />
        </a>
        </ListItem>
        <ListItem _hover={{ boxShadow: 'xl' }}>
          <a href='/servicepages/neurocare'>
        <Image width="300px" height="150px" boxShadow={'lg'} borderRadius={'2xl'} src={require('../img/centrelogos/neurocentre.jpg')} alt='Neuro Centre' />
        </a>
        </ListItem>
        <ListItem _hover={{ boxShadow: 'xl' }}>
        <a href='/servicepages/radiology'>
        <Image width="300px" height="150px" boxShadow={'lg'} borderRadius={'2xl'} src={require('../img/centrelogos/radiology.jpg')} alt='Radiology' />
        </a>
        </ListItem>
        <ListItem _hover={{ boxShadow: 'xl' }}>
        <a href='/servicepage'>
        <Image width="300px" height="150px" boxShadow={'lg'} borderRadius={'2xl'} src={require('../img/centrelogos/entcare.jpg')} alt='ENT Centre' />
        </a>
        </ListItem>
      </List>
      </Flex>
    </>
  )
}

export default LatestItems2